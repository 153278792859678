<script setup lang="ts">
import {useNamespace} from "@/stores/namespaces";
import {computed} from "vue";
import {streamCluster} from "~/stores/serverClusters";
import composeTemplate from "~/assets/demo-docker-compose.yaml?raw"
import {useEnvironment} from "~/stores/spaces";
import {activateEnvironmentCluster} from "~/calls/environment";
import {useDemoHelpers} from "~/utils/demo";
import CodeBlock from "vue3-code-block";
import {useCodeTheme} from "~/utils/codeTheme";

const config = getConfig()
const router = useRouter()
const accessToken = ref<string>("")
const clusterToken = ref<string>("")
const timeoutReached = ref(false)

const props = defineProps<{
  workspaceId: string,
  environmentId: string,
  clusterId: string,
  onFinished?: () => Promise<void>
}>()

const {
  serverEnvironmentForDocker,
  frameworkEnvironmentForDocker
} = useDemoHelpers(props.workspaceId, props.environmentId, props.clusterId)
const {codeTheme} = useCodeTheme()

const workspaceId = props.workspaceId
const environmentId = props.environmentId

const {namespace} = useNamespace(workspaceId, environmentId)
const {environment} = useEnvironment()
const {cluster} = streamCluster(workspaceId, environmentId, props.clusterId)

watchEffect(() => {
  accessToken.value = namespace.value?.accessTokens[0]?.token || ""
  clusterToken.value = cluster.value?.accessTokens[0]?.accessToken || ""
})

const hasAxonServer = computed(() => {
  return cluster.value?.nodes?.length > 0
})

const hasService = (name: string) => {
  return namespace?.value?.applications?.filter(a => a.name === name)?.length > 0 || false
}

const hasPaymentProcessor = computed(() => hasService("Payment Processor"))
const hasRentalCommandService = computed(() => hasService("Rental Command"))
const hasRentalPaymentService = computed(() => hasService("Rental Payment"))
const hasRentalQueryService = computed(() => hasService("Rental Query"))
const hasRentalUiService = computed(() => hasService("Rental UI"))

const fullyDeployed = computed(() => {
  const hasAxonServerValue = hasAxonServer.value
  const hasPaymentProcessorValue = hasPaymentProcessor.value
  const hasRentalCommandServiceValue = hasRentalCommandService.value
  const hasRentalPaymentServiceValue = hasRentalPaymentService.value
  const hasRentalQueryServiceValue = hasRentalQueryService.value
  const hasRentalUiServiceValue = hasRentalUiService.value
  return hasAxonServerValue
      && hasPaymentProcessorValue
      && hasRentalCommandServiceValue
      && hasRentalPaymentServiceValue
      && hasRentalQueryServiceValue
      && hasRentalUiServiceValue
})

const compose = computed(() => {
  return composeTemplate
      .replaceAll("$$APP_ENVIRONMENT$$", frameworkEnvironmentForDocker.value)
      .replaceAll("$$SERVER_ENVIRONMENT$$", serverEnvironmentForDocker.value)
})

function download() {
  const blob = new Blob([compose.value], {type: 'application/yaml'});
  const elem = window.document.createElement('a');
  elem.href = window.URL.createObjectURL(blob);
  elem.download = "docker-compose.yaml";
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
  step.value = 'start'
}

const loadingTimeout = setTimeout(() => {
  if (!accessToken.value || !cluster.value) {
    timeoutReached.value = true
  }
}, 10000)
onUnmounted(() => clearTimeout(loadingTimeout))
onMounted(() => {
  activateEnvironmentCluster(workspaceId, environmentId)
})

const finishLoading = ref(false)
const finish = () => {
  finishLoading.value = true
  if (props.onFinished) {
    props.onFinished()
        .then(() => router.push(`/workspace/${workspaceId}/env/${environmentId}/start`))
        .finally(() => finishLoading.value = false)
  } else {
    router.push(`/workspace/${workspaceId}/env/${environmentId}/start`)
  }

}

const step = ref('intro')
const hasAllRequiredInfo = computed(() => {
  return !!accessToken.value && !!clusterToken.value
})
</script>


<template>
  <div>
    <template v-if="step === 'intro'">

      <p class="my-2">
        It's easy to run our Bike Rental demo application on your machine. Simply download a Docker Compose
        file and run it. This will start all the services needed for the demo on your machine.
      </p>

      <v-alert class="my-2">

        <p>
          Are you unfamiliar with Docker Compose? <a href="#" class="text-primary"
                                                     @click.stop="step = 'docker_explanation'">
          Click here to learn more about it.
        </a>
        </p>
      </v-alert>


      <v-alert type="error" class="mt-4" v-if="timeoutReached">
        <p> Something went wrong initializing your environment for the demo. Please contact AxonIQ if the problem
          persists.</p>
      </v-alert>
      <v-progress-linear indeterminate color="primary" class="mt-8" height="25" rounded v-else-if="!hasAllRequiredInfo">
        Preparing your environment...
      </v-progress-linear>
      <template v-else>
        <div class="text-center mt-4">
          <v-btn color="primary" @click="download()" class="w-100">
            <DownloadIcon class="mr-2"/>
            Download Compose file
          </v-btn>
          <p class="mt-2">
            <a href="#" @click.stop="step='start'" class="text-primary mt-2">I already have the file on my computer</a>
          </p>
        </div>

      </template>

    </template>

    <template v-else-if="step === 'docker_explanation'">
      <p>
        Docker Compose is a tool that allows you to define and run multi-container Docker applications. With Compose,
        you
        use a YAML file to configure your application’s services. Then, with a single command, you create and start all
        the services from your configuration.
      </p>
      <p class="mt-4">
        Make sure you have Docker and Docker Compose installed on your machine. You can verify this by running
        the following commands:
      </p>
      <CodeBlock :code="`docker --version\ndocker-compose --version`" :highlightjs="true" :theme="codeTheme"
                 lang="shell" class="mt-2"/>
      <p class="mt-2">
        This should show something like:
      </p>
      <pre class="ml-2">Docker version 24.0.2, build cb74dfc</pre>
      <pre class="ml-2">Docker Compose version v2.19.1</pre>
      <p class="mt-2">If you cannot execute these commands successfully, we recommend following the <a
          href="https://docs.docker.com/get-docker/" target="_blank" class="text-primary">Docker Setup guide</a> to
        install Docker and Docker Compose before continuing.</p>

      <v-btn color="primary" @click="step='intro'" class="w-100 mt-4">
        <CheckIcon class="mr-2"/>
        I am Ready to continue
      </v-btn>
    </template>

    <template v-else-if="step === 'start'">
      <p>
        Great! You have the Docker Compose file. Now you can start all the services by running the following command in
        the folder where you saved the file:
      </p>
      <CodeBlock :code="`docker compose up`" :highlightjs="true" :theme="codeTheme"
                 lang="shell" class="mt-2"/>
      <p class="mt-2">
        This will start all the services. This might take a few minutes, depending on the speed of your computer and
        internet.
        You can see the progress of the services starting and connecting to AxonIQ Console below:
      </p>

      <v-row class="mt-4">
        <v-col class="pl-8">
          <div class="d-flex gap-4">
            <CheckIcon class="text-success" v-if="hasAxonServer"/>
            <v-progress-circular color="secondary" v-else size="small" indeterminate></v-progress-circular>
            <span>Axon Server</span>
          </div>
          <div class="d-flex gap-4 mt-1">
            <CheckIcon class="text-success" v-if="hasPaymentProcessor"/>
            <v-progress-circular color="secondary" v-else size="small" indeterminate></v-progress-circular>
            <span>Payment Processor Service</span>
          </div>
          <div class="d-flex gap-4 mt-1">
            <CheckIcon class="text-success" v-if="hasRentalCommandService"/>
            <v-progress-circular color="secondary" v-else size="small" indeterminate></v-progress-circular>
            <span>Rental Command Service</span>
          </div>
          <div class="d-flex gap-4 mt-1">
            <CheckIcon class="text-success" v-if="hasRentalPaymentService"/>
            <v-progress-circular color="secondary" v-else size="small" indeterminate></v-progress-circular>
            <span>Rental Payments Service</span>
          </div>
          <div class="d-flex gap-4 mt-1">
            <CheckIcon class="text-success" v-if="hasRentalQueryService"/>
            <v-progress-circular color="secondary" v-else size="small" indeterminate></v-progress-circular>
            <span>Rental Query Service</span>
          </div>
          <div class="d-flex gap-4 mt-1">
            <CheckIcon class="text-success" v-if="hasRentalUiService"/>
            <v-progress-circular color="secondary" v-else size="small" indeterminate></v-progress-circular>
            <span>Rental UI</span>
          </div>
        </v-col>
      </v-row>

      <p class="mt-8">
        Once all services are up and connected, you can continue to the next step.
        Want something to do while you wait?
        <a href="https://github.com/AxonIQ/bike-rental-quick-start" target="_blank" class="text-primary">Click here to
          see the source code
          on Github!</a>
      </p>

      <div class="mt-2">
        <v-btn color="primary" @click="step='finished'" :disabled="!fullyDeployed" class="w-100">
          <CheckIcon class="mr-2"/>
          Continue
        </v-btn>
      </div>
    </template>
    <template v-else-if="step === 'finished'">
      <p>
        Well done, all services are up and running! You can now go to your Demo Workspace and explore the power of
        AxonIQ Console.
      </p>
      <p class="mt-4">We also have a ton of resources for you to learn about building scalable systems! Check them out
        below.</p>
      <v-row class="mt-4">
        <v-col cols="4" class="text-center" align-self="center">
          <div class="d-flex justify-center">
            <LogoAcademy :size="50"></LogoAcademy>
          </div>
          <p class="h5">AxonIQ Academy</p>
        </v-col>
        <v-col cols="8">
          <p>
            AxonIQ Academy provides online courses and training for developers and architects. Learn how to build
            scalable and distributed applications with Axon Framework and Axon Server, based on Domain-Driven Design.
          </p>
          <p class="mt-2">
            <a href="https://academy.axoniq.io" target="_blank" class="text-primary">Visit AxonIQ Academy</a>
          </p>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col cols="4" class="text-center" align-self="center">
          <div class="d-flex justify-center">
            <BrandGithubIcon :size="50"></BrandGithubIcon>
          </div>
          <p class="h5">Github</p>
        </v-col>
        <v-col cols="8" align-self="center">
          <p>
            Axon Framework and many of our other projects are open-source. You can find the source code on Github, as
            well as many other resources.
          </p>
          <p class="mt-2">
            <a href="https://github.com/AxonFramework/" target="_blank" class="text-primary">Visit our Github</a>
          </p>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col cols="4" class="text-center" align-self="center">
          <div class="d-flex justify-center">
            <LogoDiscuss :size="50"></LogoDiscuss>
          </div>
          <p class="h5">AxonIQ Discuss</p>
        </v-col>
        <v-col cols="8">
          <p>
            AxonIQ Discuss is our community forum. Here you can ask questions, share your knowledge and connect with
            other Axon users.
          </p>
          <p class="mt-2">
            <a href="https://discuss.axoniq.io" target="_blank" class="text-primary">Visit AxonIQ Discuss</a>
          </p>
        </v-col>
      </v-row>
      <div class="mt-4">
        <v-btn color="primary" @click="finish" class="w-100" :loading="finishLoading">
          <ArrowRightIcon class="mr-2"/>
          Go to my workspace
        </v-btn>
      </div>
    </template>
  </div>
</template>
